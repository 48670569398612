import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";

export interface IEventBase {
    id: string
    transaction_id: string
    parent_id: string
    delay: string
    path: string
    create_at: Date
}

export interface IEvent extends IEventBase {
    body: string
    caller_trace: string[]
}

export interface IEventExec {
    evt_id: string
    exec_id: string
    begin_at: Date
    end_at: Date
    err: string
    stack: string[]
}

export interface IDataItem {
    key: string
    path: string
    create_at: string
    delay: string
    body: string
    children?: IDataItem[]
}

export interface IEventInfo {
    event: IEvent
    execs: IEventExec[]
}


export function get(): Promise<IDataItem[]> {
    return request.request<unknown, AxiosResponse, unknown>({
        url: "maintain/microservice/events",
        method: 'get',
    } as AxiosRequestConfig).then((list): IEventBase[] => {
        list.data.forEach((i: { create_at: string | number | Date; }) => i.create_at = new Date(i.create_at))
        return list.data
    }).then(list => sort(list) ?? [])
}

const sort = (list: IEventBase[], key?: string): IDataItem[] | undefined => {
    const arr = list.filter(i => i.parent_id == key).map(i => convert(i))
    arr.forEach(a => {
        a.children = sort(list, a.key)
    })
    if (arr.length === 0) return undefined
    else return arr
}

const convert = (info: IEventBase): IDataItem => {
    return {body: '', create_at: info.create_at.toLocaleString(), delay: info.delay, key: info.id, path: info.path}
}

export async function getEventInfo(id: string): Promise<IEventInfo> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "maintain/microservice/event/info",
        params: {id: id},
        method: 'get',
    } as AxiosRequestConfig));
    return rs.data;
}

